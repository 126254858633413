import React from 'react'
import { useTranslation } from 'react-i18next';

const SFeatures = () => {
  const { t } = useTranslation();
  return (
    <section className="section section-lg sf-section">
      <div className="container">
        <div className="row text-center">
          <h3 className="av-title">{t('specialExpertiseTitle')}</h3>
        </div>
        <div className="row row-30 sf-row">
          <div className="col-sm-12 col-md-6 col-lg-4 wow-outer">
            <article className="box-minimal">
              <div className="sf-container">
                <img src="/images/qualified-employees.svg" alt="icon" />
                <div className="box-minimal-main wow-outer">
                  <h4 className="box-minimal-title wow slideInDown">
                  {t('qualifiedEmployeesTitle')}
                  </h4>
                </div>
              </div>
              <div>
                <p className="wow fadeInUpSmall">
                {t('qualifiedEmployeesDescription')}
                </p>
              </div>
            </article>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 wow-outer">
            <article className="box-minimal">
              <div className="sf-container">
                <img src="/images/free-consultation.svg" alt="icon" />
                <div className="box-minimal-main wow-outer">
                  <h4 className="box-minimal-title wow slideInDown">
                  {t('freeConsultationsTitle')}
                  </h4>
                </div>
              </div>
              <div className="box-minimal-main wow-outer">
                <p className="wow fadeInUpSmall" data-wow-delay=".1s">
                {t('freeConsultationsDescription')}
                </p>
              </div>
            </article>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4 wow-outer">
            <article className="box-minimal">
              <div className="sf-container wow fadeIn" data-wow-delay=".1s">
                <img src="/images/guaranteed.svg" alt="icon" />
                <div className="box-minimal-main wow-outer">
                  <h4 className="box-minimal-title wow slideInDown">
                  {t('guaranteedResultsTitle')}
                  </h4>
                </div>
              </div>
              <div className="box-minimal-main wow-outer">
                <p className="wow fadeInUpSmall" data-wow-delay=".2s">
                {t('guaranteedResultsDescription')}
                </p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SFeatures
