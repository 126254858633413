import React from 'react'
import { Link } from 'gatsby-plugin-react-i18next'

import { Swiper, SwiperSlide } from 'swiper/react'
import { useViewport } from '@/hooks/useViewport'
import { useTranslation, Trans } from 'react-i18next'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

// import required modules
import { Pagination, Autoplay } from 'swiper'

const Slider = () => {
  const { isMobile } = useViewport()
  const { t } = useTranslation()

  return (
    <section
      className="swiper-container swiper-slider swiper-slider-light"
      data-loop="true"
      data-autoplay="5000"
      data-speed="1200"
      data-effect="interleave"
      style={{ zIndex: 0 }}
    >
      <Swiper
        slidesPerView={1}
        loop={true}
        pagination={true}
        autoplay={{
          delay: 5500,
        }}
        modules={[Pagination, Autoplay]}
        className="swiper-wrapper"
      >
        <SwiperSlide>
          <div
            className="swiper-slide"
            style={{
              backgroundImage: !isMobile ? 'url(/images/sl-1.jpg)' : 'url(/images/mobile-bg.png)',
            }}
            data-slide-bg={!isMobile ? 'images/sl-1.jpg' : 'images/mobile-bg.png'}
          >
            <div className="slide-inner">
              <div className="container">
                <div className="swiper-slide-caption">
                  <h1 className="wow-outer">
                    <span className="fw-light wow-outer">
                      <span className="wow" data-caption-animate="slideInDown">
                        {t('doYouNeed')}
                      </span>
                    </span>
                    <span className="fw-bold wow-outer">
                      <span className="wow" data-caption-animate="slideInDown" data-wow-delay="0">
                        {t('anAccountant')}
                      </span>
                    </span>
                  </h1>
                  <div className="wow-outer button-outer">
                    <Link
                      className="button button-lg light-btn button-winona wow"
                      to="/contact"
                      placeholder=""
                      data-caption-animate="slideInDown"
                      data-wow-delay=".2s"
                    >
                      <Trans>{t('freeConsultation')}</Trans>
                      <img src="/images/arrow-right.svg" className="ms-2" alt="arrow-icon" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="swiper-slide"
            style={{
              backgroundImage: !isMobile ? 'url(/images/sl-3.jpg)' : 'url(/images/mobile-bg.png)',
            }}
            data-slide-bg={!isMobile ? 'images/sl-3.jpg' : 'images/mobile-bg.png'}
          >
            <div className="slide-inner">
              <div className="container">
                <div className="swiper-slide-caption">
                  <h1 className="wow-outer">
                    <span className="fw-light wow-outer">
                      <span className="wow" data-caption-animate="slideInDown">
                        {t('beSureYouAre')}
                      </span>
                    </span>
                    <span className="fw-bold wow-outer">
                      <span className="wow" data-caption-animate="slideInDown" data-wow-delay="0">
                        {t('onTheRightWay')}
                      </span>
                    </span>
                  </h1>
                  <div className="wow-outer button-outer">
                    <Link
                      className="button button-lg light-btn button-winona wow"
                      to="/contact"
                      placeholder=""
                      data-caption-animate="slideInDown"
                      data-wow-delay=".2s"
                    >
                      <Trans>{t('freeConsultation')}</Trans>
                      <img src="/images/arrow-right.svg" className="ms-2" alt="arrow-icon" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="swiper-slide"
            style={{
              backgroundImage: !isMobile ? 'url(/images/sl-2.jpg)' : 'url(/images/mobile-bg.png)',
            }}
            data-slide-bg={!isMobile ? 'images/sl-2.jpg' : 'images/mobile-bg.png'}
          >
            <div className="slide-inner">
              <div className="container">
                <div className="swiper-slide-caption">
                  <h1 className="wow-outer">
                    <span className="fw-light wow-outer">
                      <span className="wow" data-caption-animate="slideInDown">
                        {t('yourPersonal')}
                      </span>
                    </span>
                    <span className="fw-bold wow-outer">
                      <span className="wow" data-caption-animate="slideInDown" data-wow-delay="0">
                        {t('onlineAccountant')} <br />
                        {t('online')}
                      </span>
                    </span>
                  </h1>
                  <div className="wow-outer button-outer">
                    <Link
                      className="button button-lg light-btn button-winona wow"
                      to="/contact"
                      placeholder=""
                      data-caption-animate="slideInDown"
                      data-wow-delay=".2s"
                    >
                      <Trans>{t('freeConsultation')}</Trans>
                      <img src="/images/arrow-right.svg" className="ms-2" alt="arrow-icon" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  )
}

export default Slider
