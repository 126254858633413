import React from 'react'
import Layout from '@/components/Layout'
import Ho from '@/components/Home'
import Footer from '@/components/Footer'
import Header from '@/components/Header'
import { useLocation } from '@reach/router'
import SEO from '@/components/seo'
import { graphql } from 'gatsby'

const Home = () => {
  return (
    <Layout>
      <SEO title="Home" canonical={`${useLocation().host}`} />
      <Header />
      <Ho />
      <Footer />
    </Layout>
  )
}

export default Home

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
