import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby-plugin-react-i18next'

const Services = () => {
  const { t, i18n } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      allServicesJson {
        edges {
          node {
            name
            id
            image
            descriptionDetail
            slug
            lang
          }
        }
      }
    }
  `)

  const services = data.allServicesJson.edges.filter(({ node }) => node.lang === i18n.language)

  return (
    <section className="section-lg text-center serv-section">
      <div className="container">
        <h3 className="wow-outer av-title">
          <span className="wow slideInUp">{t('servicesTitle')}</span>
        </h3>
        <p className="wow-outer text-width-3 mt-3 mb-0">
          <span className="wow slideInDown text-width-1">{t('servicesDescription')}</span>
        </p>
        <div className="row row-30 offset-top-50">
          {services
            .map(({ node }) => (
              <div key={node.id} className="col-sm-6 col-lg-3 wow-outer">
                <article className="thumbnail-light wow slideInLeft">
                  <Link
                    to={`/services/${node.slug}`}
                    placeholder=""
                    className="thumbnail-light-media"
                  >
                    <img
                      className="thumbnail-light-image"
                      src={`/images/${node.image}`}
                      alt=""
                      width="270"
                      height="300"
                    />
                  </Link>
                  <h5 className="thumbnail-dark-title">
                    <Link to={`/services/${node.slug}`} placeholder="">
                      {node.name}
                    </Link>
                  </h5>
                </article>
              </div>
            ))
            .slice(0, 4)}
        </div>
      </div>
      <div className="wow-outer button-outer offset-top-70">
        <Link className="button-winona offset-top-2 wow slideInUp" to="/services" placeholder="">
          {t('viewAllServices')}
          <img src="/images/arrow-right.svg" className="ms-2" alt="arrow-icon" />
        </Link>
      </div>
    </section>
  )
}

export default Services
