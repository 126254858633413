import { Link } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'

const CTA = () => {
  const { t } = useTranslation()
  return (
    <section className="section section-xs text-center cta-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col">
            <div className="box-cta-thin">
              <h4>
                <span className="wow slideInRight">{t('learnTheCost')}</span>
                <br />
                <span className="fw-bolder">{t('accountingSolutions')} </span> <span></span>{' '}
                <span> {t('rightNow')}</span>
              </h4>
              <div className="button-outer">
                <Link
                  className="button light-btn button-winona wow slideInLeft"
                  to="/services#pricing"
                  placeholder=""
                >
                  {t('viewAllPricing')}
                  <img src="/images/arrow-right.svg" className="ms-2" alt="arrow-icon" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CTA
