import { Link } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'

const CCTA = () => {
  const { t } = useTranslation()
  return (
    <section className="section section-xs text-center cc-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col cc-col">
            <h3 className="av-title">
              <span className="wow slideInDown">
                {t('chooseTheBest')} <br /> <span className="fw-bold">{t('Accountant')}</span>
              </span>
            </h3>
            <p className="wow-outer offset-top-3">
              <span className="wow slideInDown" data-wow-delay=".05s">
                {t('fitYourCorporateRequirements')}
              </span>
            </p>
            <div className="button-outer">
              <Link
                className="button light-btn button-winona wow slideInDown"
                to="/services#pricing"
                placeholder=""
                data-wow-delay=".1s"
              >
                {t('viewAllPricing')}
                <img src="/images/arrow-right.svg" className="ms-2" alt="arrow-icon" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CCTA
