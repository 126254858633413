import React from 'react'
import Image from '@/components/Image'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby-plugin-react-i18next'

const FW = () => {
  const { t } = useTranslation()
  return (
    <section className="section section-lg fw-section">
      <div className="fw-container">
        <div className="container">
          <div className="row row-50 justify-content-center justify-content-lg-between flex-lg-row-reverse">
            <div className="col-md-12 col-lg-6 col-xl-5">
              <h3 className="wow-outer av-title">
                <span className="wow slideInDown">{t('aFewWordsAboutUs')}</span>
              </h3>
              <p className="wow-outer">
                <span className="wow slideInDown" data-wow-delay=".05s">
                  {t('aboutUsDescription1')}
                </span>
              </p>
              <p className="wow-outer">
                <span className="wow slideInDown" data-wow-delay=".1s">
                  {t('aboutUsDescription2')}
                </span>
              </p>
              <div className="wow-outer button-outer">
                <Link className="wow slideInDown" data-wow-delay=".1s" placeholder="" to="/about">
                  {t('learnMore')}
                  <img src="/images/arrow-right.svg" className="ms-2" alt="arrow-icon" />
                </Link>
              </div>
            </div>
            <div className="col-md-8 col-lg-6 wow-outer d-flex align-items-center">
              <Image name="acc-team-home.png" classes="img-responsive wow slideInLeft" />
              {/* <img
              className="img-responsive wow slideInLeft"
              src="/images/acc-team-home.png"
              alt=""
              width="570"
              height="368"
            /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FW
