import React from 'react'
import Slider from '@/components/Home/Slider'
import FW from '@/components/Home/FewWords'
import SFeatures from '@/components/Home/SmallFt'
import CTA from '@/components/Home/Cta'
import Services from '@/components/Home/Services'
import Testimonial from '@/components/Home/Testimonials'
import CCTA from '@/components/Home/CCTA'
import Contact from '@/components/Home/Contact'
import Info from '@/components/Home/Contact/info'
import GoogleReview from '../GoogleReview'

const Ho = () => {
  return (
    <>
      <Slider />
      <FW />
      <SFeatures />
      <CTA />
      <Services />
      <CCTA />
      {/* <Testimonial /> */}
      <GoogleReview/>
      <Contact />
    </>
  )
}

export default Ho
