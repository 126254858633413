import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import './reviewStyle.css'

// import required modules
import { Pagination, Autoplay, Navigation } from 'swiper'
import { useViewport } from '@/hooks/useViewport'
import { useTranslation } from 'react-i18next';

const data = [
  {
    id: 1,
    name: 'Maria Elisa Sil',
    image: '/images/customer-default.png',
    username: '@Maria',
    commentDate: '3 months ago',
    review: 'Excellent professional.',
    rate: 5,
  },
  {
    id: 2,
    name: 'Marlon Morales',
    image: '/images/customer-default.png',
    username: '@marlon',
    commentDate: '4 years ago',
    review:
      'Llevo haciendo mis impuestos en este lugar desde el 2010 y siempre los hare con ellos, la razon es porque son personas muy profesionales, listos para ayudarte. Ellos pueden ayudarte a nivel personal y corporativo. Mi tio fue el primero en llegar a este lugar desde  hace mas de 12 años  y luego paso el contacto a cada uno de los miembros de nuestra familia. Todos aqui son muy amables, te recomiendo que hagas una cita con tiempo de anticipacion porque ellos se mantienen siempre llenos, nunca hemos tenido problemas con el IRS con ellos. Dedicado equipo que su meta principal es que sus clientes siempre esten satistecho y siempre cumpliendo con las leyes federales. Gracias por tomarte el de ayudarnos a todos siempre. Estan localizados en el terce piso de West Garden Plaza en el Centro de ese piso. Recuerda ser pasiente porque te toca esperar un poco.',
    rate: 5,
  },
  {
    id: 3,
    name: 'Indira Acosta',
    image: '/images/customer-default.png',
    username: '@Indira',
    commentDate: '2 years ago',
    review: 'Best service ever!',
    rate: 5,
  },
  {
    id: 4,
    name: 'Amarylis Cruz',
    image: '/images/customer-default.png',
    username: '@Amarylis',
    commentDate: '1 year ago',
    review:
      'Todos los años hago los taxes con ellos es un lugar muy serio y una excelente atención al cliente.',
    rate: 5,
  },
  {
    id: 5,
    name: 'Hasiel Garcia',
    image: '/images/customer-default.png',
    username: '@Hasiel',
    commentDate: '3 years ago',
    review:
      'Excellent service, very good attention and always punctual in the appointment. I recommend it.',
    rate: 5,
  },
  {
    id: 6,
    name: 'Sergio Correa',
    image: '/images/customer-default.png',
    username: '@Sergio',
    commentDate: '4 years ago',
    review: 'Muy buen servicio. Vale la espera.',
    rate: 5,
  },
  {
    id: 7,
    name: 'Ancelys Moreno',
    image: '/images/customer-default.png',
    username: '@Ancelys',
    commentDate: '3 months ago',
    review:
      'I came to this place with a mess, I didn’t even know where to start. Luis waited for me until 5:45pm from my 5:00pm appt. Which is very well appreciated for us working moms. After 2 hours my personal and companies’ taxes were completed. It was very smooth and Luis is very knowledgeable. Recommend 100%',
    rate: 5,
  },
  {
    id: 8,
    name: 'Carlos Hernandez',
    image: '/images/customer-default.png',
    username: '@Carlos',
    commentDate: '3 years ago',
    review: 'El mejor servicio...',
    rate: 5,
  },
]
const GoogleReview = () => {
  const { isDesktop, isWidescreen, isMobile } = useViewport()
  const { t } = useTranslation();

  const StartRating = (start: number) => {
    const normalizeStartNumber = () => {
      if (!start) return 1
      if (start <= 1) return 1
      if (start >= 5) return 5
      return Math.ceil(start)
    }

    return (
      <div className="StartRatingWrapper">
        {Array.from({ length: normalizeStartNumber() }, (_, idx) => (
          <div className="StarImage" key={idx}></div>
        ))}
      </div>
    )
  }

  return (
    <section className="section section-lg text-center t-section">
      <div className="container">
        <h3 className="wow-outer av-title">
          <span className="wow slideInDown" style={{ marginBottom: '3rem' }}>
          {t('ourCustomersReviews')}
          </span>
        </h3>
        <Swiper
          pagination={{
            type: 'fraction',
          }}
          slidesPerView={(isWidescreen && 3) || (isDesktop && 2) || 1}
          loop={true}
          autoplay={{
            delay: 2500,
          }}
          navigation={false}
          modules={[Pagination, Navigation, Autoplay]}
        >
          {data.map((el, idx) => (
            <SwiperSlide key={idx}>
              <blockquote className="ReviewsCardWrapper" style={{ margin: '0 auto' }}>
                {StartRating(el.rate)}
                <div className="ReviewComment ">{el.review}</div>

                <div className="ReviewerWrapper">
                  <img alt="avatar" src={el.image} className="ReviewrAvatar" />
                  <div className="ReviewrInfoWrapper">
                    <p className="ReviewrName">{el.name}</p>
                    <p className="ReviewerUserNameAndDate">{`${el.username} • ${el.commentDate}`}</p>
                  </div>
                </div>
                <img alt="GoogleImage" src="/images/Group.svg" className="GoogleImage" />
              </blockquote>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  )
}

export default GoogleReview
